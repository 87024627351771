import React, { useState, useEffect } from "react";
import { Button, Card, Col, Row, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import PageTitle from "../../../components/PageTitle";
import "./style.css";
import AddFont from "./AddFont";

interface FontProps {
  id: number;
  font_path: string;
  name: string;
  created_at: string;
  category: {
    id: number;
    name: string;
  };
  sub_category: string;
  status?: string;
}

const SelectedFonts = () => {
  const [fonts, setFonts] = useState<FontProps[]>([]);
  const [filteredFonts, setFilteredFonts] = useState<FontProps[]>([]);
  const [status, setStatus] = useState<{ [key: number]: string }>({});
  const [showModal, setShowModal] = useState(false); // Modal visibility for adding/editing fonts
  const [showDeleteModal, setShowDeleteModal] = useState(false); // Modal visibility for delete confirmation
  const [selectedFont, setSelectedFont] = useState<FontProps | null>(null);
  const [fontToDelete, setFontToDelete] = useState<FontProps | null>(null);
  const [categoryName, setCategoryName] = useState<string>("Selected Fonts");
  const { categoryId } = useParams<{ categoryId: string }>(); // Get categoryId from URL params

  // Fetch fonts from the API
  const fetchData = async () => {
    try {
      const response = await fetch(`https://backend.inkox.com/api/fonts`);
      const data: FontProps[] = await response.json();

      // Filter the fonts based on the categoryId from the URL
      const filtered = data.filter((font) => font.category.id === parseInt(categoryId || "0", 10));

      // Set the filtered fonts to state
      setFilteredFonts(filtered);

          // Set category name
       
            setCategoryName(filtered[0].category.name);
      
    

      // Set default statuses
      const initialStatus = filtered.reduce((acc: any, font, index: number) => {
        acc[index] = font.status || "active";
        return acc;
      }, {});

      setFonts(data); // Save full data if needed later
      setStatus(initialStatus);
    } catch (error) {
      console.error("Error fetching font data:", error);
    }
  };

  // const selectedCategoryName = filteredFonts.length > 0 ? filteredFonts[0].category.name : "Selected Fonts";

  useEffect(() => {
    fetchData();
  }, [categoryId]);

  const handleEditClick = (item: FontProps) => {
    setSelectedFont(item);
    setShowModal(true);
  };

  const handleDeleteClick = (item: FontProps) => {
    setFontToDelete(item);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    if (!fontToDelete) return;

    try {
      const response = await fetch(`https://backend.inkox.com/api/fonts/${fontToDelete.id}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        throw new Error("Failed to delete font");
      }

      setFilteredFonts((prev) => prev.filter((font) => font.id !== fontToDelete.id));
      setShowDeleteModal(false);
      setFontToDelete(null);
    } catch (error) {
      console.error("Error deleting font:", error);
    }
  };

  const handleFontAddedOrUpdated = (updatedFont: FontProps) => {
    const newFont = {
      ...updatedFont,
      // Add cache-busting parameter
      font_path: `https://backend.inkox.com/storage/${updatedFont.font_path}`,
      name: updatedFont.name,
    };
  
    if (selectedFont) {
      // Update existing font in the state
      setFilteredFonts((prevFonts) =>
        prevFonts.map((font) =>
          font.id === updatedFont.id ? newFont : font
        )
      );
    } else {
      // Add the new font to the state
      setFilteredFonts((prevFonts) => [...prevFonts, newFont]);
    }
  
    setShowModal(false);
    setSelectedFont(null);
  };
  

  const toggleStatus = (index: number) => {
    setStatus((prevStatus) => ({
      ...prevStatus,
      [index]: prevStatus[index] === "active" ? "deactive" : "active",
    }));
  };

  return (
    <>
      <Card>
        <Card.Body>
          <PageTitle
            breadCrumbItems={[
              { label: "Fonts", path: "/apps/ecommerce/fonts", active: true },
              { label: categoryName, path: `/apps/ecommerce/fonts` },
            ]}
            title={categoryName}
          />

          <Row className="align-items-center">
            <Col lg={12}>
              <div className="text-lg-end mt-xl-0 mt-2">
                <Button
                  className="btn mb-2 me-2"
                  style={{ backgroundColor: "#1cbed9", borderColor: "#1cbed9" }}
                  onClick={() => {
                    setSelectedFont(null);
                    setShowModal(true);
                  }}
                >
                  <i className="mdi mdi-basket me-1"></i> Add New Font
                </Button>
              </div>
            </Col>
          </Row>

          <h4 className="header-title mb-3">Fonts Detail</h4>
          <div className="table-responsivess">
            <div className="abdu-cls">
              {filteredFonts.length > 0 ? (
                filteredFonts.map((item, i) => (
                  <div key={i} className="inner-clipart">
                    <style>
                      {`
                        @font-face {
                          font-family: '${item.name}';
                          src: url('${item.font_path}');
                        }
                      `}
                    </style>
                    <div className="font-display" style={{ fontFamily: item.name }}>
                      {item.name}
                    </div>

                    <button onClick={() => handleEditClick(item)} className="btn btn-xs btn-light">
                      <i className="mdi mdi-pencil"></i> Edit
                    </button>

                    <button onClick={() => handleDeleteClick(item)} className="btn btn-xs btn-light">
                      <i className="mdi mdi-trash-can-outline"></i> Delete
                    </button>

                    <button
                      onClick={() => toggleStatus(i)}
                      style={{ height: "50%" }}
                      className={classNames("badge", {
                        "bg-soft-success text-success": status[i] === "active",
                        "bg-soft-danger text-danger": status[i] === "deactive",
                      })}
                    >
                      {status[i] === "active" ? "Active" : "Deactive"}
                    </button>
                  </div>
                ))
              ) : (
                <p>No fonts found for this category.</p>
              )}
            </div>
          </div>
        </Card.Body>
      </Card>

      {/* Modal for adding/editing font */}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{selectedFont ? "Edit Font" : "Add New Font"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddFont
          categoryId={categoryId || ""}
            categoryName={categoryName}
            selectedFont={selectedFont}
            onFontAddedOrUpdated={handleFontAddedOrUpdated}
          />
        </Modal.Body>
      </Modal>

      {/* Confirmation Modal for Delete */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this font?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SelectedFonts;
