import React, { useState } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PageTitle from "../../../components/PageTitle";
import { FormInput } from "../../../components";
import FileUploader from "../../../components/FileUploader";

interface GuideBox {
  guidelineBoxText: string;
  leftPercent: number;
  topPercent: number;
  widthPercent: number;
  heightPercent: number;
}

interface Part {
  name: string;
  [key: string]: any;
}

interface FormValues {
  name: string;
  parts: Part[];
}

const ToolApparel: React.FC = () => {
  const [productCanvasId, setProductCanvasId] = useState<string>("");
  const [parts, setParts] = useState<Part[]>([]);
  const [files, setFiles] = useState<File[]>([]);

  const schemaResolver = yupResolver(
    yup.object().shape({
      name: yup.string().required("Please enter Project Name"),
    })
  );

  const methods = useForm<FormValues>({ resolver: schemaResolver });
  const { handleSubmit, register, control, formState: { errors } } = methods;

  const handleProductCanvasIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProductCanvasId(e.target.value);
  };

  const handleAddPart = () => {
    const partIndex = parts.length + 1;
    setParts([
      ...parts,
      {
        name: "",
        [`ProductImageComponent${partIndex}`]: {
          image: "",
        },
        [`CanvasComponent${partIndex}`]: {
          canvasSize: "dynamic",
        },
        [`DesignAreaComponent${partIndex}`]: {
          designAreaText: "",
          designableArea: {
            leftPercent: 0,
            topPercent: 0,
            widthPercent: 0,
            heightPercent: 0,
          },
          guideboxes: [
            {
              guidelineBoxText: "",
              leftPercent: 0,
              topPercent: 0,
              widthPercent: 0,
              heightPercent: 0,
            },
          ],
        },
        workingArea: {
          leftPercent: 0,
          topPercent: 0,
          widthPercent: 0,
          heightPercent: 0,
        },
      },
    ]);
  };

  // New function to handle part removal
  const handleRemovePart = (index: number) => {
    const updatedParts = [...parts];
    updatedParts.splice(index, 1); // Remove the part at the specified index
    setParts(updatedParts);
  };

  const handleAddGuideBox = (partIndex: number) => {
    const updatedParts = [...parts];
    const designAreaKey = `DesignAreaComponent${partIndex + 1}`;
    updatedParts[partIndex][designAreaKey].guideboxes.push({
      guidelineBoxText: "",
      leftPercent: 0,
      topPercent: 0,
      widthPercent: 0,
      heightPercent: 0,
    });
    setParts(updatedParts);
  };

  const handleFileUpload = (file: File, partIndex: number) => {
    const updatedParts = [...parts];
    const imageKey = `ProductImageComponent${partIndex + 1}.image`;
    updatedParts[partIndex][imageKey] = file; // Store the file
    setParts(updatedParts);

    const updatedFiles = [...files];
    updatedFiles[partIndex] = file;
    setFiles(updatedFiles);
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    console.log(data);
  
    const encodeFileToBase64 = (file: File): Promise<string | null> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
      });
    };
  
    const updatedParts = await Promise.all(
      data.parts.map(async (part, index) => {
        const file = files[index];
        const base64Image = file ? await encodeFileToBase64(file) : null;
  
        return {
          name: part.name,
          description: JSON.stringify(part),
          image: base64Image,
        };
      })
    );
  
    const jsonData = {
      name: data.name,
      parts: updatedParts,
    };
  
    console.log("JSON payload:", jsonData);
  
    try {
      const response = await fetch('https://backend.inkox.com/api/tool-apparels', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonData),
      });
  
      // Check if the response is OK
      if (!response.ok) {
        // If it's not, log the error status and message
        const errorText = await response.text(); // Get the response as text (which might be HTML)
        console.error('Error response from API:', response.status, errorText);
        throw new Error(`Error: ${response.status}`);
      }
  
      const result = await response.json();
      alert("CanvasID Created Succesfully");
      console.log('tool-apparels saved:', result);
    } catch (error) {
      alert("Error saving CanvasID");
      console.error('Error saving tool-apparels:', error);
    }
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Ecommerce", path: "/apps/ecommerce/details" },
          {
            label: "Canvas Id",
            path: "/apps/ecommerce/details",
            active: true,
          },
        ]}
        title={"Canvas Id"}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Body>
                <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                  Apparel
                </h5>
                <FormInput
                  name="name"
                  label="Product Canvas Id"
                  placeholder="e.g : Apple iMac"
                  containerClass={"mb-3"}
                  register={register}
                  key="productname"
                  errors={errors}
                  control={control}
                  onChange={handleProductCanvasIdChange}
                />

                {/* Show "Add Part" button here only if there are no parts */}
                {parts.length === 0 && productCanvasId && (
                  <button
                    type="button"
                    className="btn w-sm btn-primary waves-effect waves-light"
                    onClick={handleAddPart}
                  >
                    Add Part
                  </button>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Render all parts */}
        {parts.map((part, index) => {
          const partIndex = index + 1;
          return (
            <Row key={index}>
              <Col lg={12}>
                <Card>
                  <Card.Body>
                    <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                      Part {partIndex}
                    </h5>
                    <FormInput
                      name={`parts[${index}].name`}
                      label="Part Name"
                      placeholder="e.g : Front"
                      containerClass={"mb-3"}
                      register={register}
                      errors={errors}
                      control={control}
                    />
                    {/* Product Image Upload */}
                    <Form.Group className="mb-3 ">
                      <Form.Label>Product Image {partIndex}</Form.Label>
                      <FileUploader
                        onFileUpload={(files: any) => handleFileUpload(files[0], index)}
                      />
                    </Form.Group>

                    <FormInput
                      name={`parts[${index}].CanvasComponent${partIndex}.canvasSize`}
                      label={`Canvas Size ${partIndex}`}
                      placeholder="e.g : dynamic"
                      value="Dynamic"
                      containerClass={"mb-3"}
                      register={register}
                      errors={errors}
                      control={control}
                      className="tool-apprealnone"
                    />
 <Row className="tool-apprealborder">
                    <FormInput
                      name={`parts[${index}].DesignAreaComponent${partIndex}.designAreaText`}
                      label={`Design Area ${partIndex}`}
                      placeholder="e.g : Front Design"
                      containerClass={"mb-3"}
                      register={register}
                      errors={errors}
                      control={control}
                       value="Design area"
                       className="tool-apprealnone"

                    />

                    {/* Designable Area - Display in a single row */}
                   
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].DesignAreaComponent${partIndex}.designableArea.leftPercent`}
                          label="Left Percent"
                          placeholder="e.g : 25"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].DesignAreaComponent${partIndex}.designableArea.topPercent`}
                          label="Top Percent"
                          placeholder="e.g : 25"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].DesignAreaComponent${partIndex}.designableArea.widthPercent`}
                          label="Width Percent"
                          placeholder="e.g : 50"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].DesignAreaComponent${partIndex}.designableArea.heightPercent`}
                          label="Height Percent"
                          placeholder="e.g : 50"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                    </Row>

                    {/* Guideboxes - Repeatable Section */}
                    {part[`DesignAreaComponent${partIndex}`].guideboxes.map(
                      (box: GuideBox, boxIndex: number) => (
                        <div key={boxIndex} className="row tool-apprealbordertwo">
                          <h6>Guidebox {boxIndex + 1}</h6>
                          <FormInput
                            name={`parts[${index}].DesignAreaComponent${partIndex}.guideboxes[${boxIndex}].guidelineBoxText`}
                            label="Guidebox Text"
                            placeholder="e.g : Left Chest"
                            containerClass={"mb-3"}
                            register={register}
                            errors={errors}
                            control={control}
                            className="inputguideline"
                          />

                          {/* Guidebox Area - Display in a single row */}
                          <Row>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].DesignAreaComponent${partIndex}.guideboxes[${boxIndex}].leftPercent`}
                                label="Left Percent"
                                placeholder="e.g : 0"
                                type="number"
                                containerClass={"mb-3"}
                                register={register}
                                 step="0.01"
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].DesignAreaComponent${partIndex}.guideboxes[${boxIndex}].topPercent`}
                                label="Top Percent"
                                placeholder="e.g : 89"
                                type="number"
                                containerClass={"mb-3"}
                                register={register}
                                 step="0.01"
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].DesignAreaComponent${partIndex}.guideboxes[${boxIndex}].widthPercent`}
                                label="Width Percent"
                                placeholder="e.g : 10"
                                type="number"
                                containerClass={"mb-3"}
                                 step="0.01"
                                register={register}
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].DesignAreaComponent${partIndex}.guideboxes[${boxIndex}].heightPercent`}
                                label="Height Percent"
                                placeholder="e.g : 10"
                                type="number"
                                 step="0.01"
                                containerClass={"mb-3"}
                                register={register}
                                errors={errors}
                                control={control}
                              />
                            </Col>
                          </Row>
                        </div>
                      )
                    )}

                    <button
                      type="button"
                      className="btn btn-sm btn-secondary mb-3"
                      onClick={() => handleAddGuideBox(index)}
                    >
                      Add Guidebox
                    </button>

                    {/* Working Area - Display in a single row */}
                    <Row className="tool-apprealnone">
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].workingArea.leftPercent`}
                          label="Working Area Left Percent"
                          placeholder="e.g : 0"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                          value="0"
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].workingArea.topPercent`}
                          label="Working Area Top Percent"
                          placeholder="e.g : 25"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                            value="0"
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].workingArea.widthPercent`}
                          label="Working Area Width Percent"
                          placeholder="e.g : 25"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                            value="0"
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].workingArea.heightPercent`}
                          label="Working Area Height Percent"
                          placeholder="e.g : 25"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                            value="0"
                        />
                      </Col>
                    </Row>
                    {parts.length > 0 && productCanvasId && (
  <div className="text-end mb-3">
    <button
      type="button"
      className="btn w-sm btn-primary waves-effect waves-light me-2"
      onClick={handleAddPart}
    >
      Add Part
    </button>
    <button
      type="button"
      className="btn w-sm btn-danger waves-effect waves-light me-2"
      onClick={() => handleRemovePart(index)}
    >
      Remove Part
    </button>
  </div>
)}

                  </Card.Body>
                </Card>
              </Col>
            </Row>
          );
        })}

        {/* Render "Add Part" button below the last part */}
        {/* {parts.length > 0 && productCanvasId && (
          <Row>
            <Col lg={12}>
              
            </Col>
          </Row>
        )} */}

        <Row>
          <Col>
            <div className="text-center mb-3">
              <button
                type="button"
                className="btn w-sm btn-light waves-effect me-1"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn w-sm btn-success waves-effect waves-light me-1"
              >
                Save
              </button>
              <button
                type="button"
                className="btn w-sm btn-danger waves-effect waves-light me-1"
              >
                Delete
              </button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default ToolApparel;
