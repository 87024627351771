import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate } from "react-router-dom";
import PageTitle from "../../../components/PageTitle";
import { FormInput } from "../../../components";
import FileUploader from "../../../components/FileUploader";

interface GuideBox {
  guidelineBoxText: string;
  leftPercent: number;
  topPercent: number;
  widthPercent: number;
  heightPercent: number;
}

interface Part {
  name: string;
  [key: string]: any;
}

interface FormValues {
  name: string;
  parts: Part[];
}

const EditToolApparel: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [productCanvasId, setProductCanvasId] = useState<string>("");
  const [parts, setParts] = useState<Part[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  console.log("parts",parts);
  const schemaResolver = yupResolver(
    yup.object().shape({
      name: yup.string().required("Please enter Project Name"),
    })
  );

  const methods = useForm<FormValues>({ resolver: schemaResolver });
  const { handleSubmit, register, control, formState: { errors }, setValue } = methods;

  useEffect(() => {
    const detail = location.state?.detail;
    if (detail) {
      setProductCanvasId(detail.name);
      setValue("name", detail.name);
      
      try {
        const parsedParts = JSON.parse(detail.parts);
        setParts(parsedParts.map((part: any) => ({
          ...part,
          ...JSON.parse(part.description)
        })));
      } catch (error) {
        console.error("Error parsing parts data:", error);
      }
    }
  }, [location.state, setValue]);

  const handleProductCanvasIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProductCanvasId(e.target.value);
  };

  const handleAddPart = () => {
    const partIndex = parts.length + 1;
    setParts([
      ...parts,
      {
        name: "",
        [`ProductImageComponent${partIndex}`]: {
          image: "",
        },
        [`CanvasComponent${partIndex}`]: {
          canvasSize: "dynamic",
        },
        [`DesignAreaComponent${partIndex}`]: {
          designAreaText: "",
          designableArea: {
            leftPercent: 0,
            topPercent: 0,
            widthPercent: 0,
            heightPercent: 0,
          },
          guideboxes: [
            {
              guidelineBoxText: "",
              leftPercent: 0,
              topPercent: 0,
              widthPercent: 0,
              heightPercent: 0,
            },
          ],
        },
        workingArea: {
          leftPercent: 0,
          topPercent: 0,
          widthPercent: 0,
          heightPercent: 0,
        },
      },
    ]);
  };

  const handleAddGuideBox = (partIndex: number) => {
    const updatedParts = [...parts];
    const designAreaKey = `DesignAreaComponent${partIndex + 1}`;
    updatedParts[partIndex][designAreaKey].guideboxes.push({
      guidelineBoxText: "",
      leftPercent: 0,
      topPercent: 0,
      widthPercent: 0,
      heightPercent: 0,
    });
    setParts(updatedParts);
  };

  const handleFileUpload = (file: File, partIndex: number) => {
    const updatedParts = [...parts];
    const imageKey = `ProductImageComponent${partIndex + 1}.image`;
    updatedParts[partIndex][imageKey] = file;
    setParts(updatedParts);

    const updatedFiles = [...files];
    updatedFiles[partIndex] = file;
    setFiles(updatedFiles);
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    console.log(data);

    const encodeFileToBase64 = (file: File): Promise<string | null> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
      });
    };

    const updatedParts = await Promise.all(
      data.parts.map(async (part, index) => {
        const file = files[index];
        const base64Image = file ? await encodeFileToBase64(file) : null;

        return {
          name: part.name,
          description: JSON.stringify(part),
          image: base64Image,
        };
      })
    );

    const jsonData = {
      name: data.name,
      parts: updatedParts,
    };

    console.log("JSON payload:", jsonData);

    try {
      const response = await fetch(`https://backend.inkox.com/api/tool-apparel/update/${location.state?.detail.name}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonData),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response from API:', response.status, errorText);
        throw new Error(`Error: ${response.status}`);
      }

      const result = await response.json();
      console.log('tool-apparels saved:', result);
      alert('Tool Apparel updated successfully!');
      navigate("/apps/ecommerce/tool-apparel-detail");
    } catch (error) {
      console.error('Error saving tool-apparels:', error);
      alert('Error updating Tool Apparel');
    }
  };

  const deleteToolApparel = async () => {
    try {
      const response = await fetch(`https://backend.inkox.com/api/tool-apparel/delete/${location.state?.detail.name}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to delete tool apparel');
      }

      const data = await response.json();
      console.log(data.message);
      alert('Tool Apparel deleted successfully!');
      navigate("/apps/ecommerce/tool-apparel-detail");
    } catch (error) {
      console.error('Error:', error);
      alert('Error deleting Tool Apparel');
    }
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Ecommerce", path: "/apps/ecommerce/tool-apparel-detail" },
          {
            label: "Edit Canvas Id",
            path: "/apps/ecommerce/tool-apparel-detail",
            active: true,
          },
        ]}
        title={"Edit Canvas Id"}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Body>
                <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                  Apparel
                </h5>
                <FormInput
                  name="name"
                  label="Product Canvas Id"
                  placeholder="e.g : Apple iMac"
                  containerClass={"mb-3"}
                  register={register}
                  key="productname"
                  errors={errors}
                  control={control}
                  onChange={handleProductCanvasIdChange}
                />

                <button
                  type="button"
                  className="btn w-sm btn-primary waves-effect waves-light"
                  onClick={handleAddPart}
                >
                  Add Part
                </button>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {parts.map((part, index) => {
          const partIndex = index + 1;
          return (
            <Row key={index}>
              <Col lg={12}>
                <Card>
                  <Card.Body>
                    <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                      Part {partIndex}
                    </h5>
                    <FormInput
                      name={`parts[${index}].name`}
                      label="Part Name"
                      placeholder="e.g : Front"
                      containerClass={"mb-3"}
                      register={register}
                      errors={errors}
                      control={control}
                      defaultValue={parts[index].name}
                    />
                    <Form.Group className="mb-3">
                      <Form.Label>Product Image {partIndex}</Form.Label>
                      <FileUploader
                        onFileUpload={(files: any) => handleFileUpload(files[0], index)}
                      />
                      <Row>
                
                          <Col key={index} xs={6} md={3} lg={2}>
                            <div className="position-relative">
                              <img
                                src={"https://laravel-incox.hebesol.com"+parts[index]?.[`ProductImageComponent${partIndex}`]?.image}
                                alt={`Product Image ${index + 1}`}
                                style={{ width: "100%",height:"auto" }}
                              />
                              <button
                                type="button"
                                className="btn btn-danger btn-sm position-absolute"
                                style={{ top: '5px', right: '5px' }}
                                // onClick={() => handleImageRemove2(index)}
                              >
                                Remove
                              </button>
                            </div>
                          </Col>
                       
                        </Row>
                    </Form.Group>

                    <FormInput
                      name={`parts[${index}].CanvasComponent${partIndex}.canvasSize`}
                      label={`Canvas Size ${partIndex}`}
                      placeholder="e.g : dynamic"
                      containerClass={"mb-3"}
                      register={register}
                      errors={errors}
                      control={control}
                      defaultValue={parts[index]?.[`CanvasComponent${partIndex}`]?.canvasSize}
                       className="tool-apprealnone"
                    />
  <Row  className="tool-apprealborder">
                    <FormInput
                      name={`parts[${index}].DesignAreaComponent${partIndex}.designAreaText`}
                      label={`Design Area Text ${partIndex}`}
                      placeholder="e.g : Front Design"
                      containerClass={"mb-3"}
                      register={register}
                      errors={errors}
                      control={control}
                      defaultValue={parts[index]?.[`DesignAreaComponent${partIndex}`]?.designAreaText}
                      className="tool-apprealnone"
                    />

                  
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].DesignAreaComponent${partIndex}.designableArea.leftPercent`}
                          label="Left Percent"
                          placeholder="e.g : 25"
                          type="number"
                          step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                          defaultValue={parts[index]?.[`DesignAreaComponent${partIndex}`]?.designableArea.leftPercent}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].DesignAreaComponent${partIndex}.designableArea.topPercent`}
                          label="Top Percent"
                          placeholder="e.g : 25"
                          type="number"
                          step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                          defaultValue={parts[index]?.[`DesignAreaComponent${partIndex}`]?.designableArea.topPercent}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].DesignAreaComponent${partIndex}.designableArea.widthPercent`}
                          label="Width Percent"
                          placeholder="e.g : 50"
                          type="number"
                          step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                          defaultValue={parts[index]?.[`DesignAreaComponent${partIndex}`]?.designableArea.widthPercent}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].DesignAreaComponent${partIndex}.designableArea.heightPercent`}
                          label="Height Percent"
                          placeholder="e.g : 50"
                          type="number"
                          step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                          defaultValue={parts[index]?.[`DesignAreaComponent${partIndex}`]?.designableArea.heightPercent}
                        />
                      </Col>
                    </Row>

                    {part[`DesignAreaComponent${partIndex}`]?.guideboxes.map(
                      (box: GuideBox, boxIndex: number) => (
                        <div key={boxIndex} className="row tool-apprealbordertwo">
                          <h6>Guidebox {boxIndex + 1}</h6>
                          <FormInput
                            name={`parts[${index}].DesignAreaComponent${partIndex}.guideboxes[${boxIndex}].guidelineBoxText`}
                            label="Guidebox Text"
                            placeholder="e.g : Left Chest"
                            containerClass={"mb-3"}
                            register={register}
                            errors={errors}
                            control={control}
                            className="inputguideline"
                            defaultValue={parts[index]?.[`DesignAreaComponent${partIndex}`]?.guideboxes[boxIndex].guidelineBoxText}
                          />

                          <Row>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].DesignAreaComponent${partIndex}.guideboxes[${boxIndex}].leftPercent`}
                                label="Left Percent"
                                placeholder="e.g : 0"
                                type="number"
                                step="0.01"
                                containerClass={"mb-3"}
                                register={register}
                                errors={errors}
                                control={control}
                                defaultValue={parts[index]?.[`DesignAreaComponent${partIndex}`]?.guideboxes[boxIndex].leftPercent}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].DesignAreaComponent${partIndex}.guideboxes[${boxIndex}].topPercent`}
                                label="Top Percent"
                                placeholder="e.g : 89"
                                type="number"
                                step="0.01"
                                containerClass={"mb-3"}
                                register={register}
                                errors={errors}
                                control={control}
                                defaultValue={parts[index]?.[`DesignAreaComponent${partIndex}`]?.guideboxes[boxIndex].topPercent}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].DesignAreaComponent${partIndex}.guideboxes[${boxIndex}].widthPercent`}
                                label="Width Percent"
                                placeholder="e.g : 10"
                                type="number"
                                step="0.01"
                                containerClass={"mb-3"}
                                register={register}
                                errors={errors}
                                control={control}
                                defaultValue={parts[index]?.[`DesignAreaComponent${partIndex}`]?.guideboxes[boxIndex].widthPercent}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].DesignAreaComponent${partIndex}.guideboxes[${boxIndex}].heightPercent`}
                                label="Height Percent"
                                placeholder="e.g : 10"
                                type="number"
                                step="0.01"
                                containerClass={"mb-3"}
                                register={register}
                                errors={errors}
                                control={control}
                                defaultValue={parts[index]?.[`DesignAreaComponent${partIndex}`]?.guideboxes[boxIndex].heightPercent}
                              />
                            </Col>
                          </Row>
                        </div>
                      )
                    )}

                    <button
                      type="button"
                      className="btn btn-sm btn-secondary mb-3"
                      onClick={() => handleAddGuideBox(index)}
                    >
                      Add Guidebox
                    </button>

                    <Row className="tool-apprealnone">
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].workingArea.leftPercent`}
                          label="Working Area Left Percent"
                          placeholder="e.g : 0"
                          type="number"
                          step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                          defaultValue={parts[index]?.workingArea.leftPercent}
                        />
                      </Col>
                      <Col md={3}>
                      <FormInput
                          name={`parts[${index}].workingArea.topPercent`}
                          label="Working Area Top Percent"
                          placeholder="e.g : 25"
                          type="number"
                          step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                          defaultValue={parts[index]?.workingArea.topPercent}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].workingArea.widthPercent`}
                          label="Working Area Width Percent"
                          placeholder="e.g : 25"
                          type="number"
                          step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                          defaultValue={parts[index]?.workingArea.widthPercent}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].workingArea.heightPercent`}
                          label="Working Area Height Percent"
                          placeholder="e.g : 25"
                          type="number"
                          step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                          defaultValue={parts[index]?.workingArea.heightPercent}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          );
        })}

        <Row>
          <Col>
            <div className="text-center mb-3">
              <button
                type="button"
                className="btn w-sm btn-light waves-effect me-1"
                onClick={() => navigate("/apps/ecommerce/tool-apparel-detail")}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn w-sm btn-success waves-effect waves-light me-1"
              >
                Save
              </button>
              <button
                type="button"
                className="btn w-sm btn-danger waves-effect waves-light me-1"
                onClick={deleteToolApparel}
              >
                Delete
              </button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default EditToolApparel;