import React, { useState, useEffect } from "react";
import { Button, Card, Col, Row, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import PageTitle from "../../../components/PageTitle";
import "./style.css";
import AddClipartCategories from "./AddClipartCategories";

interface ClipartDetailProps {
  revenueHistory: {
    id: number;
    image_path: string;
    name: string;
    created_at: string;
    category: any;
    reference: string;
    tags: string;
    status: string;
  }[];
}

const Cliparts = () => {
  const [revenueHistory, setRevenueHistory] = useState<ClipartDetailProps["revenueHistory"]>([]);
  const [status, setStatus] = useState<{ [key: number]: string }>({});
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [selectedItem, setSelectedItem] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State for delete confirmation modal
  const [itemToDelete, setItemToDelete] = useState<any>(null);
  const navigate = useNavigate();

  // Fetch categories from the API
  const fetchData = async () => {
    try {
      const response = await fetch("https://backend.inkox.com/api/categories");
      const data = await response.json();

      // Set the default status as "active" for items where the status is missing
      const initialStatus = data.reduce((acc: any, item: any, index: number) => {
        acc[index] = item.status || "active";
        return acc;
      }, {});

      setRevenueHistory(data);
      setStatus(initialStatus);
    } catch (error) {
      console.error("Error fetching sub-categories data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEditClick = (item: any) => {
    setSelectedItem(item); // Set the selected item to be edited
    setShowModal(true); // Open the modal
  };

  const handleCategoryClick = (categoryId: number) => {
    navigate(`/apps/ecommerce/subcategories/${categoryId}`); // Navigate to subcategories page
  };

  const toggleStatus = (index: number) => {
    setStatus((prevStatus) => ({
      ...prevStatus,
      [index]: prevStatus[index] === "active" ? "deactive" : "active",
    }));
  };

  const handleCategoryCreated = () => {
    fetchData(); // Refresh the list after saving
    setShowModal(false); // Close the modal after save
    setSelectedItem(null);
  };

  const handleCloseModal = () => {
    setShowModal(false); 
    setSelectedItem(null); // Clear selectedItem when closing the modal
  };

  const handleDeleteClick = (item: any) => {
    setItemToDelete(item); // Set the item to delete
    setShowDeleteModal(true); // Open the delete confirmation modal
  };

  const confirmDelete = async () => {
    if (!itemToDelete) return;

    try {
      const response = await fetch(`https://backend.inkox.com/api/categories/${itemToDelete.id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Failed to delete category');
      }

      setShowDeleteModal(false); // Close the confirmation modal
      fetchData(); // Refresh the list to reflect the deleted item
    } catch (error) {
      console.error('Error deleting category:', error);
    }
  };


  return (
    <>
      <Card>
        <Card.Body>
          <div className="add-new-category">
            <PageTitle
              breadCrumbItems={[
                { label: "Cliparts", path: "/apps/ecommerce/cliparts" },
              ]}
              title={"Cliparts"}
            />

            <Row className="align-items-center">
              <Col lg={12}>
                <div className="text-lg-end mt-xl-0 mt-2">
                  <Button
                    className="btn  mb-2 me-2"
                    style={{ backgroundColor: "#1cbed9", borderColor: "#1cbed9" }}
                    onClick={() => setShowModal(true)} // Open the modal when clicked
                  >
                    <i className="mdi mdi-basket me-1"></i> Add New Category
                  </Button>
                </div>
              </Col>
            </Row>
          </div>

          <h4 className="header-title mb-3">Categories Detail</h4>
          <div className="table-responsivess">
            <div className="abdu-cls">
              {(revenueHistory || []).map((item, i) => (
                <div key={i} className="inner-clipart">
                  <img
                    src={`https://laravel-incox.hebesol.com/storage/${item.image_path}`}
                    className="image-clipartcategory"
                    alt={item.name}
                    onClick={() => handleCategoryClick(item.id)} // Navigate to subcategories page on image click
                  />
                  <div>{item.name}</div>
                  <button
                    onClick={() => handleEditClick(item)}
                    className="btn btn-xs btn-light"
                  >
                    <i className="mdi mdi-pencil"></i>
                  </button>
                  <button className="btn btn-xs btn-light"  onClick={() => handleDeleteClick(item)}>
                    <i className="mdi mdi-trash-can-outline"></i>
                  </button>

                  {/* Status Button */}
                  <button
                    style={{ height: "18%" }}
                    className={classNames("badge", {
                      "bg-soft-warning text-warning": status[i] === "pending",
                      "bg-soft-success text-success": status[i] === "active",
                      "bg-soft-danger text-danger": status[i] === "deactive",
                    })}
                  >
                    {status[i] === "active" ? "Active" : status[i] === "deactive" ? "Deactive" : "Pending"}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </Card.Body>
      </Card>

      {/* Modal for adding new category */}
      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{selectedItem ? 'Edit Category' : 'Add New Category'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddClipartCategories onCategoryCreated={handleCategoryCreated} selectedItem={selectedItem} />
        </Modal.Body>
      </Modal>


       {/* Confirmation Modal for Delete */}
       <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this category?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Cliparts;
